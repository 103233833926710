<template>
  <v-row no-gutters justify="center" style="height:80vh" align="center">
    <v-fade-transition>
      <v-card
        rounded="lg"
        v-show="imageReady"
        flat
        class="pa-5"
        width="370"
        max-width="370"
      >
        <v-row no-gutters justify="center">
          <img
            id="loginImage"
            class="mx-5 px-5 pt-5 mt-5"
            style="width: 80%; max-height: 180px; object-fit: contain;"
            src="https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FNovoRPM%20Horiz.svg?alt=media&token=83350ffc-36cb-4603-9b59-d4a0dd968043"
          />
        </v-row>

        <v-row
          justify="center"
          class="font-weight-light mt-7 mb-7"
          style="font-size: 24px"
          no-gutters
        >
          <span>Log in to your account</span>
        </v-row>
        <v-form ref="loginForm">
          <v-text-field
            outlined
            :readonly="lockEmailInput"
            label="Email"
            name="email"
            type="text"
            required
            v-model="email"
            :rules="emailRules"
            v-on:keyup.enter="submitLogin"
          />

          <v-text-field
            ref="password"
            outlined
            id="password"
            label="Password"
            name="password"
            :append-icon="isPassVisible ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="() => (isPassVisible = !isPassVisible)"
            :type="isPassVisible ? 'text' : 'password'"
            required
            v-model="password"
            :rules="passwordRules"
            v-on:keyup.enter="submitLogin"
          />
        </v-form>

        <v-row justify="space-between" no-gutters>
          <v-btn
            v-if="shouldAllowSignup"
            color="primary"
            text
            @click="switchToRegister"
            >Sign Up</v-btn
          >
          <v-btn
            v-if="appTheme && !appTheme.hidePasswordReset"
            color="primary"
            text
            @click="resetPassword"
            >Forgot Password?</v-btn
          >
          <v-spacer />
          <v-btn color="primary" depressed @click="submitLogin">
            <v-fade-transition hide-on-leave>
              <span v-show="!loginLoading">
                Log In
              </span>
            </v-fade-transition>
            <v-fade-transition hide-on-leave>
              <v-progress-circular
                class="px-6"
                v-show="loginLoading"
                size="20"
                indeterminate
              />
            </v-fade-transition>
          </v-btn>
        </v-row>
      </v-card>
    </v-fade-transition>
  </v-row>
</template>

<script>
// @ is an alias to /src
// import LoginComponent from "@/components/LoginComponent.vue";
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'
import HelperMixin from '@/core/mixins/HelperMixin'
import consts from '@/consts'

export default {
  mixins: [HelperMixin],
  name: 'loginpage',
  components: {},
  data() {
    return {
      imageReady: false,
      tokenHere: null,
      loginLoading: false,
      signInType: 'Log In',
      isPassVisible: false,
      userGroupInviteToken: null,
      userGroup: null,
      userGroupInvitePermissions: null,
      patientShareToken: null,
      patient: null,
      patientSharePermissions: null,
      inviteEmail: null,
      selectedPartner: null,
      userGroupSignUpList: [],
      partnerID: null,
      partnerAccessCode: null,
      providerID: null,
      providerAffiliateCode: null,
      email: null,
      password: null,
      emailRules: [v => !!v || 'E-mail is required'],
      passwordRules: [v => !!v || 'Password is required'],
      shouldAllowSignup: false,
      affiliateCode: null,
      provider: null,
    }
  },
  computed: {
    ...mapState('auth', [
      'user',
      'appTitle',
      'subdomain',
      'loginURL',
      'appTheme',
    ]),
    lockEmailInput() {
      if (this.userGroupInviteToken || this.patientShareToken) {
        return true
      }
      return false
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    tagline() {
      return 'Powered by OneChart Health'
    },
    version() {
      return consts.version
    },
  },
  watch: {
    user(val) {
      // user is back. if there is no token to apply, just go to the correct page. if there is a token, apply the token first
      // this will be called again when the token is applied.
      if (val) {
        if (this.tokenHere) {
          this.applyRegistrationToken({ loginToken: this.tokenHere })
          this.tokenHere = null
        } else {
          if (val.partnerID) {
            // you are part of a partner, go to the partner dashboard
            // look for a token here? dont apply if you are already part of a partner?
            this.$router.push({ name: 'Taskboard' })
          } else {
            // you are not part of the partner. go to the user dashboard
            // maybe look for a token here?
            this.$router.push({ name: 'PatientFacingView' })
          }
        }
      }
    },
  },
  methods: {
    ...mapActions('auth', ['signIn', 'signOut', 'applyRegistrationToken']),
    ...mapActions('provider', ['getProviderAuth']),
    parseURLParameters() {
      const search = this.$route.query
      if (search.loginToken) {
        this.tokenHere = search.loginToken
        console.log('found login token')
        let info = this.parseSignupJWT(search.loginToken)
        console.log('token identified, parsing')
        console.log(info)
        if (search.email) {
          console.log(search.email)
          console.log(decodeURIComponent(search.email))
          this.inviteEmail = decodeURIComponent(search.email)
          this.email = decodeURIComponent(search.email)
        }
        if (info) {
          this.signUpType = info.ocType
        } else {
          this.toast('error', 'Error loading token')
        }
      } else {
        this.signUpType = 'Token Log In'
      }
    },
    switchToRegister() {
      this.$router.push({ name: 'Register', query: this.$route.query })
    },
    resetPassword() {
      this.$router.push({ name: 'PasswordReset' })
    },
    submitLogin() {
      this.loginLoading = true
      if (this.$refs.loginForm.validate()) {
        const credentials = {
          email: this.email,
          password: this.password,
        }
        if (this.tokenHere) {
          credentials.payload = { loginToken: this.tokenHere }
        }
        this.signIn(credentials)
          .then(() => {
            // bus.$emit('toast', { type: 'success', text: 'Logged In' })
          })
          .catch(error => {
            console.log(error)
            switch (error.code) {
              case 'auth/user-not-found':
                bus.$emit('toast', {
                  type: 'error',
                  text: 'User not found. Sign up instead?',
                })
                break
              case 'auth/invalid-email':
                bus.$emit('toast', { type: 'error', text: 'Invalid Email' })
                break
              case 'auth/wrong-password':
                bus.$emit('toast', { type: 'error', text: 'Invalid password' })
                break
              default:
                bus.$emit('toast', { type: 'error', text: error.code })
            }
            this.loginLoading = false
          })
      } else {
        this.loginLoading = false
      }
    },
  },
  mounted() {
    // only show the rest when the image has been loaded
    let img = document.getElementById('loginImage')
    let vm = this
    img.onload = function() {
      vm.imageReady = true
    }

    img.onerror = function() {
      vm.imageReady = true
    }
    this.signOut()
    this.loginLoading = false
    this.parseURLParameters()
  },
}
</script>
